<template>
    <div class="news">
        <nav-bar class="nav-bar"></nav-bar>
        <div class="news-content d-flex">
            <div class="content-right">
                <div class="right-desc" v-for="(item, index) in pagelist.list" :key="index">
                    <div class="desc-item" @click="details(item.id)">
                        <div class="title text-over">{{ item.title }}</div>
                        <div class="mt-11 content text-over-2" v-html='item.content'>
                        </div>
                        <div class="mt-11 day">{{ item.createTime }}</div>
                    </div>
                </div>
                <div class="flex-acjc">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page.sync="currentPage" :page-size=this.pagesize layout="prev, pager, next, jumper"
                        :total=this.total>
                    </el-pagination>
                </div>
            </div>
        </div>
        <bottom-bar class="home-bottom" />
    </div>
</template>
<script>

import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import { PageList, NewsInfo } from '@/api/news'
import { getInfo } from '@/api/login'

import NavBar from '../../components/content/navBar/NavBar'
import BottomBar from '../../components/content/bottomBar/BottomBar'

import {getToken,setToken,removeToken} from '@/utils/cookies.js'

export default {
    name: 'News',
    components: {
        NavBar,
        BottomBar
    },
    data() {
        return {
            currentPage: 1,//初始页      
            pagesize: 12,//每页的数据            
            users: {},//用户信息
            total: 0,//总数
            listQuery: {
                "pageIndex": this.currentPage,
                "pageSize": this.pagesize,
                "newsTypeId": 5
            },
            pagelist: [],
        }
    },
    created() {
        //用户信息
        this.userInfo();
        //列表
        this.onPageList();
    },
    methods: {
        //查看详情
        details(id) {
            this.$router.push({
                name: '/newsDetail',
                params: {
                    "id": id,
                    "mdd": this.$refs.nav.mdd
                }
            });
        },
        //用户信息
        userInfo() {
            var token = getToken();
            if (token) {
                getInfo(token).then(response => {
                    if (response.status == 200) {
                        this.users = response.data;
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                })
            }
        },
        //列表
        onPageList() {
            this.listLoading = true;
            this.listQuery.pageIndex = this.currentPage;
            this.listQuery.pageSize = this.pagesize;
            PageList(this.listQuery).then(response => {
                if (response) {
                    if (response.status == 200) {
                        this.pagelist = response.data;
                        this.total = response.data.total;
                    } else {
                        MessageBox.alert(response.message, "错误", {
                            confirmButtonText: '确定',
                            type: 'error'
                        })
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                }
            })
        },
        //初始页currentPage、初始每页数据数pagesize和数据data
        handleSizeChange(size) {
            this.pagesize = size;
            this.onTeacherPageList();
        },
        //点击第几页
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.onTeacherPageList();
        },
        //图片加载
        imgSrc(value) {
            return this.common.imgUrl + value;
        }
    }
}
</script>
<style scoped lang="less">
.news {
    min-width: 1000px;

    .nav-bar {
        .bar-icon {
            border-radius: 50%;
            vertical-align: -4px;
            margin-right: 7px;
            width: 1.05rem;
            height: 1.05rem;
        }
    }

    .news-content {
        padding-left: 9.1rem;
        padding-right: 9.1rem;
        margin-top: 1.1rem;
        padding-bottom: 1.8rem;

        .content-left {
            width: 19%;
            background-color: #fff;
            margin-right: 0.8rem;
            height: fit-content;

            .left-item {
                border-bottom: 1px solid #E7E7E7;
                padding: 1rem 0.95rem 1rem 1rem;

                &:last-child {
                    border-bottom: none;
                }

                .type {
                    color: #6A6E76;
                    font-size: 0.65rem;
                    font-weight: 600;
                    width: calc(100% - 2rem);
                }

                .active {
                    color: #3E7EFF;
                }

                .arrow {
                    width: 0.5rem;
                    height: 0.5rem;
                }
            }
        }

        .content-right {
            flex: 1;

            .right-desc {
                background-color: #fff;
                padding: 0.4rem 1.45rem 0.4rem;
                margin-bottom: 1.75rem;

                .title {
                    color: #303133;
                    font-weight: 600;
                    font-size: 0.75rem;
                }

                .content {
                    color: #6A6E76;
                    font-size: 0.5rem;
                    line-height: 1.08rem;
                }

                .day {
                    color: #9C9DA0;
                    font-size: 0.5rem;
                }

                .desc-item {
                    margin-top: 1rem;
                    border-bottom: 1px solid #E7E7E7;
                    padding-bottom: 1.08rem;

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}
</style>